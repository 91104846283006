import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Heading, Box } from "@chakra-ui/core"
import BlockContent from "@sanity/block-content-to-react"
import { motion } from "framer-motion"

import { Author, Bubble, Layout, Slices } from "../../components"
import { BlockRenderer } from "../utils/htmlSerializer"
import { internalLink, link } from "../utils/marks/"

const Post = ({ data }) => {
  const doc = data.post
  const source = doc.body.find((item) => item._type === "source")

  if (!doc) return null
  return (
    <Layout>
      {/* Titel & Intro */}
      <Box
        maxW="6xl"
        mx="auto"
        px="4"
        textAlign={{ base: "left", xl: "center" }}
        pt={{ base: "10", xl: "32" }}
        pb={{ base: "10", xl: "24" }}>
        <motion.div
          initial={{ opacity: 0, y: "20%" }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}>
          <Heading
            textTransform="uppercase"
            fontSize={{ base: "2.75rem", xl: "5rem" }}
            pb="10"
            fontWeight="black">
            {doc.title}
          </Heading>
        </motion.div>

        <Bubble />
        <BlockContent blocks={doc._rawIntro} serializers={{ types: { block: BlockRenderer } }} />
      </Box>

      {doc.author && <Author input={doc.author} />}

      {/* Post Image */}
      <Box maxW="1340px" maxH="748px" overflow="hidden" mx="auto">
        <Img fluid={doc.mainImage.asset.fluid} />
      </Box>

      {/* Content */}
      <Box
        maxW="1100px"
        mx="auto"
        p={{ base: "4", xl: "16" }}
        bg="gray.100"
        py={{ base: "10" }}
        mt={{ base: 0, xl: "-200px" }}
        mb={{ base: 0, xl: "20" }}
        position="relative"
        zIndex="docked">
        <Slices input={doc.body} />
      </Box>
      {source && (
        <Box bg="background" color="white" py={{ base: "10", xl: "20" }}>
          <Box maxW="3xl" mx="auto" textAlign={{ base: "left", xl: "center" }} px="4">
            <BlockContent
              blocks={source._rawBlock}
              serializers={{ types: { block: BlockRenderer }, marks: { internalLink, link } }}
            />
          </Box>
        </Box>
      )}
    </Layout>
  )
}

export default Post

export const query = graphql`
  query Post($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      _rawIntro(resolveReferences: { maxDepth: 10 })
      author {
        _rawBio(resolveReferences: { maxDepth: 10 })
        name
        thumbnail {
          asset {
            url
          }
        }
      }
      mainImage {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      body {
        ... on SanityPoll {
          _id
          _type
          _key
          _rawIntro(resolveReferences: { maxDepth: 10 })
          answers {
            _key
            votes
            answer
          }
        }

        ... on SanityBodyBlock {
          _type
          _key
          _rawBlock
        }

        ... on SanityTextImage {
          _key
          _type
          direction
          _rawText(resolveReferences: { maxDepth: 10 })
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }

        ... on SanitySource {
          _key
          _type
          _rawBlock(resolveReferences: { maxDepth: 10 })
        }

        ... on SanityFullImage {
          _key
          _type
          caption
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }

        ... on SanityIframe {
          _key
          _type
          link
        }
      }
    }
  }
`
